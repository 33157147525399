import axios from "axios";

/**
    * @function getUserProfileService 
    * @param
    * @description This function is used to get user profile information
*/
export const getUserProfileService = async () => {
    try {
        const result = await axios.get(`user/profile`);
        return result;
    } catch (error) {
        return error;
    }
};