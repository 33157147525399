import { useEffect } from "react";

const FourZeroFour = () => {
    useEffect(() => {
        window.location.href = process.env.REACT_APP_MAIN_DOMAIN
        // eslint-disable-next-line
    }, [])

    return <></>
}

export default FourZeroFour;
