import Cookies from 'js-cookie'

// set token in the Cookies
export const setCookieToken = (token) => {
    Cookies.set("tokenAccess", token, {
        expires: 7
    });
};

// return the token from the Cookies
export const getToken = () => {
    const token = Cookies.get('tokenAccess');
    if (token) return token;
    return null;
}